import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TravelInfo from './TravelInfo'
import NumberFormat from 'react-number-format'
import line from './line.PNG'
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    sort: {
        border: '1px solid black',
        textAlign: "left"
    },
    offer: {
        color: 'springgreen',
        fontWeight: 600
    }
}))

export default function ContentPanel({data}){
    const classes = useStyles()
    const [requested, setRequested] = useState(false)
    const onClick = () => setRequested(!requested)

    return (
        <Paper className = {classes.paper} elevation={3} onClick={onClick}>
            <Grid container spacing={3}>
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    { requested ? <Chip label="REQUESTED" color="primary" size="small"/> : null }
                </Grid>
                <Grid item xs={6} style={{display:'inline-flex'}}>
                    <Grid item xs={2}>
                        <img src={line} alt={"logo"} /> 
                    </Grid>
                    <Grid item xs={10}>
                        <TravelInfo data={data} />
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <div>53' Reefer</div>
                </Grid>
                <Grid item xs={2}>
                    <NumberFormat 
                        value={data.miles} 
                        displayType={'text'} 
                        thousandSeparator={true} 
                        suffix={' miles'} />
                </Grid>
                <Grid item xs={2}>
                    <NumberFormat 
                        className={classes.offer} 
                        value={data.offer} 
                        displayType={'text'} 
                        thousandSeparator={true} 
                        prefix={'$'} 
                        fixedDecimalScale={true}
                        decimalScale={'2'}/>
                </Grid>
            </Grid>
        </Paper>
    )
}