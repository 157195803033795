import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography, Box } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    chipStyle: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0.5),
        },
      },
    sort: {
        border: '1px solid black',
        textAlign: "left"
    },
    showMore: {
        textAlign: 'left'
    },
    button: {
        textTransform: "none"
    },
    selectEmpty: {
        color: 'blue'
    }
}))

export default function SortBy(props) {
    const classes = useStyles()
    const [sort, setSort] = useState('');

    const handleChange = (event) => {
      setSort(event.target.value);
      console.log(event.target.value);
      props.handleSort(event.target.value)
    };

    return (
        <Grid container>
            <Grid item xs={1}>
                <Typography>Sort by:</Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                    <Select
                    value={sort}
                    onChange={handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="">Pickup Date</MenuItem>
                        <MenuItem value="dropoffDate">Dropoff Date</MenuItem>
                        <MenuItem value="price">Price</MenuItem>
                        <MenuItem value="origin">Origin</MenuItem>
                        <MenuItem value="destination">Destination</MenuItem>
                        <MenuItem value="miles">Miles</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}