import React from 'react';
import './App.css';
import NavBar from './components/NavBar'


function App() {
  return (
    <div className="App">
        <NavBar />
    </div>
  )
}

export default App;
