import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ContentPanel from '../components/ContentPanel'
import Button from '@material-ui/core/Button';
import SortBy from '../components/SortBy'
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
    chipStyle: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(0.5),
        },
      },
    sort: {
        border: '1px solid black',
        textAlign: "left"
    },
    showMore: {
        textAlign: 'left'
    },
    button: {
        textTransform: "none"
      }
}))

export default function MeetingInfo() {
    const [hasError, setErrors] = useState(false);
    const [offers, setOffers] = useState([]);
    const [sortType, setSortType] = useState('');

    const classes = useStyles()

    async function fetchData() {
        const res = await fetch(`https://convoy-mock-api.herokuapp.com/offers?limit=3&sort=${sortType}`);
        res
          .json()
          .then(res => setOffers(res))
          .catch(err => fetchData());
    }

    async function toggleButtonState() {
        const res = await fetch(`https://convoy-mock-api.herokuapp.com/offers?limit=3&offset=${offers.length}&sort=${sortType}`);
        res
          .json()
          .then(res => {
              setOffers(offers => [...offers, ...res])
              console.log(res)
          })
          .catch(err => toggleButtonState());
    };

    async function handleSort(sort) {
        setSortType(sort)
        console.log(sort, sortType, offers.length)
        const res = await fetch(`https://convoy-mock-api.herokuapp.com/offers?limit=${offers.length}&sort=${sort}`);
        res
          .json()
          .then(res => {
              setOffers(offers => [...res])
          })
          .catch(err => handleSort(sort));
    }

    useEffect(() => {
        fetchData()
    }, []);
    
    
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} className={classes.sort}>
                    <SortBy handleSort={handleSort}/>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        {offers.map( data => {
                            return (
                                <ContentPanel miles={data.miles} offer={data.offer} data={data}></ContentPanel>
                            )
                        })}
                    </div>
                </Grid>
                <Grid item xs={12} className={classes.showMore}>
                    <Button onClick={() => toggleButtonState()} className={classes.button} variant="contained" disableElevation>
                        Show More
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Kunal Sinha's Homework</Typography>
                </Grid>
            </Grid>
        </div>
    )
}