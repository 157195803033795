import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography, Box } from '@material-ui/core'
import ContentPanel from '../components/ContentPanel'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1, 
    }
}))

export default function MeetingInfo() {
    const [hasError, setErrors] = useState(false);
    const [offers, setOffers] = useState([]);
    const classes = useStyles()

    async function fetchData() {
        const res = await fetch(`https://convoy-mock-api.herokuapp.com/offers?offset=10&limit=5`);
        res
          .json()
          .then(res => setOffers(res))
          .catch(err => fetchData());
    }

    useEffect(() => {
        fetchData()
    }, []);
    
    
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography>Pending and Completed Jobs</Typography>
                </Grid>
                <Grid item xs={12}>
                    <div>
                        {offers.map( data => {
                            return (
                                <ContentPanel data={data}></ContentPanel>
                            ) 
                        })}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}