import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Moment from 'react-moment';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 900, 
    },
    root: {
        textAlign: "left",
    },
}))
export default function TravelInfo({data}){
    const classes = useStyles()
        
    return (
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <p className={classes.title}>{data.origin.city}, {data.origin.state}</p>
                    <Moment format="ddd MM/DD LT">{data.origin.pickup.start}</Moment> - <Moment format="LT">{data.origin.pickup.end}</Moment>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.title}>{data.destination.city}, {data.destination.state}</p>
                    <Moment format="ddd MM/DD LT">{data.destination.dropoff.start}</Moment> - <Moment format="LT">{data.destination.dropoff.end}</Moment>
                </Grid>
            </Grid>
    )
}